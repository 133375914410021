<template>

  <div>


    <div class="row d-none">

      <div class="col-md-12">

        <!-- Load JSON data -->
        <div class="card ">
          <div class="card-header header-elements-inline">
            <h6 class="card-title">Balance Sheet</h6>
            <div class="header-elements">
              <div class="list-icons">
                <a class="list-icons-item" data-action="collapse"></a>
                <a class="list-icons-item" data-action="reload"></a>
                <a class="list-icons-item" data-action="remove"></a>
              </div>
            </div>
          </div>

          <div class="card-body">

            <div class="tree-ajax card card-body border-left-info border-left-2 shadow-0 rounded-left-0">
              <table class="table table-xs table-columned table-hover table-responsive">
                <thead>
                  <th>Liablities</th>
                  <th></th>
                  <th>Assets</th>
                  <th></th>
                </thead>
                <tbody>

                </tbody>
                <tfoot>

                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <!-- /load JSON data -->

      </div>
    </div>

    <div  id="mycard" class="card">

      <div id="toolbar" class="fixed-table-toolbar" style="padding-right: 20px;padding-left: 20px;padding-top: 10px;">
        <div class="form-group row">
          <div class="col">
            <h5>Balance Sheet</h5>
          </div>


          <label class="col-md-1 col-form-label">As On:</label>
          <div class="col-md-2">
            <input type="date"  class="form-control" placeholder="From Date"  v-model="ason_date" >
          </div>

          <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadBalanceSheetData">Show</button>
        </div>

      </div>


      <div class="card-body">


        <div class="row" >
          <div class="col-md-6" >
            <table  id="treeliblities" class="table table-xs table-columned table-hover tree-table fancytree-container fancytree-ext-table" role="treegrid" aria-multiselectable="true" aria-readonly="true">
            <thead>
            <tr>
              <th class="d-none" style="width: 46px;"></th>
              <th style="width: 80px;">#</th>
              <th>Liablities</th>
              <th style="width: 150px;"></th>
            </tr>
            </thead>
            <tbody>

            </tbody>
              <tfoot style="background-color: lightgrey;" class="font-weight-semibold">

              <tr  v-if=" (liablity_total + asset_total) < 0" >
                <td></td>
                <td style="text-align: right;">Difference in Opening Balance</td>
                <td style="width: 120px;text-align: right;">{{ (liablity_total + asset_total) * -1  | indianFormat}}</td>
              </tr>

              <tr>
                <td></td>
                <td style="text-align: right;">Total</td>
                <td style="width: 120px;text-align: right;">{{asset_total | indianFormat}}</td>
              </tr>
              </tfoot>
          </table>
          </div>

          <div class="col-md-6" >
            <table id="treeassets" class="table table-xs table-columned table-hover tree-table fancytree-container fancytree-ext-table" role="treegrid" aria-multiselectable="true" aria-readonly="true">
              <thead>
              <tr>
                <th class="d-none" style="width: 46px;"></th>
                <th style="width: 80px;">#</th>
                <th>Assets</th>
                <th style="width: 150px;"></th>
              </tr>
              </thead>
              <tbody>

              </tbody>
              <tfoot style="background-color: lightgrey;" class="font-weight-semibold">

                <tr  v-if=" (liablity_total + asset_total) > 0" >
                  <td></td>
                  <td style="text-align: right;">Difference in Opening Balance</td>
                  <td style="width: 120px;text-align: right;">{{ liablity_total + asset_total  | indianFormat}}</td>
                </tr>

                <tr>
                  <td></td>
                  <td style="text-align: right;">Total</td>
                  <td style="width: 120px;text-align: right;">{{asset_total | indianFormat}}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div >


        <div class="context-sub-menu">
          <div class="dropdown-menu">
            <a href="#" class="dropdown-item">Action</a>
            <a href="#" class="dropdown-item">Another action</a>
            <a href="#" class="dropdown-item">Something else here</a>
            <div class="dropdown-divider"></div>
            <div class="dropdown-submenu">
              <a href="#" class="dropdown-item dropdown-toggle">More options</a>
              <div class="dropdown-menu">
                <a href="#" class="dropdown-item">Second level link</a>
                <a href="#" class="dropdown-item">Second level link</a>
                <a href="#" class="dropdown-item">Second level link</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import { userService } from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'BalanceSheetView',
    store,
    data () {
      return {
        current_ibr : localStorage.getItem('branch_code'),
        NumFormat : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }),
        ason_date: moment().format('YYYY-MM-DD'),
        fancytree: {},
        asset_total : 0.0,
        liablity_total : 0.0,
        diff_total : 0.0,
      }
    },
    created(){

    },
    mounted () {

      const self = this;


      // Dropdown submenu
      $('.context-submenu').contextmenu({
        target: '.context-sub-menu'
      }).on('hidden.bs.context',function () {
        $(this).find('.context-sub-menu .show').removeClass('show');
      });

      self.$data.ason_date = moment().format('YYYY-MM-DD');

    },
    filters:{
      formatDate(val){
        return val.slice(0, 10).split('-').reverse().join('-');
      },
      indianFormat (value) {

        if(value !== 0)
          return NumFormat.format(value);// value.toFixed(2);
        else
          return '';
      },
    },
    methods:{
      loadBalanceSheetData(){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $("#mycard").block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.balancesheet = {};

        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/balancesheet/?ibr=${self.$data.current_ibr}&date=${self.$data.ason_date}`,requestOptions).then(userService.handleResponse).then(function (data) {
          self.$data.pandl =  data;

          self.$data.asset_total = data.asset_total;
          self.$data.liablity_total = data.liablitiy_total;

          self.showBalanceSheet(data.liablities, data.assets);

        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        }).finally(function () {
          $("#mycard").unblock();
        });

      },
      showBalanceSheet(liablities, assets){
        const self = this;


        $(":ui-fancytree").fancytree("destroy");

        $('#treeliblities').fancytree({
          extensions: ['table'],
          checkbox: false,
          cache: true,
          table: {
            indentation: 20,      // indent 20px per node level
            nodeColumnIdx: 2,     // render the node title into the 2nd column
            checkboxColumnIdx: 0  // render the checkboxes into the 1st column
          },
          source: liablities,

          renderColumns: function (event, data) {

            let node = data.node, $tdList = $(node.tr).find('>td');

            $tdList.eq(0).hide();
            $tdList.eq(1).text(node.getIndexHier()).addClass('alignRight')

            $tdList.eq(3).addClass('text-right').html(indianFormat( node.data.balance));
          }
        });

        self.$data.diff_total = 0.0;
        $('#treeassets').fancytree({
          extensions: ['table'],
          checkbox: false,
          cache: true,
          table: {
            indentation: 20,      // indent 20px per node level
            nodeColumnIdx: 2,     // render the node title into the 2nd column
            checkboxColumnIdx: 0  // render the checkboxes into the 1st column
          },
          source: assets,
          renderColumns: function (event, data) {

            let node = data.node, $tdList = $(node.tr).find('>td');

            $tdList.eq(0).hide();
            $tdList.eq(1).text(node.getIndexHier()).addClass('alignRight')

            $tdList.eq(3).addClass('text-right').html(indianFormat( node.data.balance));

          },
        });

      }
    }
  }
</script>

<style scoped>

</style>
